<template>
  <section>
    <div class="content-header">
      <h2>Videos</h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li><a
            href="https://wevideo.com/view/1904876402"
            target="_blank"
          >“Career planning essentials”</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
